.App {
  background: rgb(43, 43, 44);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.new-bart-btn {
  min-width: 82px;
}

.bart-times {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.bart-row {
  display: flex;
  flex-direction: row;
  margin: 10px;
  overflow: hidden;
}
.bart-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  left: -15px;

  /* animation: shake infinite 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both; */
  /* transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; */
}
.bart-bar-background {
  position: relative;
  height: 30px;
  width: 100%;
  background: rgb(32, 32, 32);
}
.bart-bar {
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: left;
  background: rgb(108, 108, 108);
}
.time {
  white-space: nowrap;
}

.inline-select {
  position: relative;
  bottom: 2px;
  margin-left: 1em;
  margin-right: 1em;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
